import { render, staticRenderFns } from "./oddsBox.vue?vue&type=template&id=333b4d27&scoped=true"
import script from "./oddsBox.vue?vue&type=script&lang=js"
export * from "./oddsBox.vue?vue&type=script&lang=js"
import style0 from "./oddsBox.vue?vue&type=style&index=0&id=333b4d27&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "333b4d27",
  null
  
)

export default component.exports